<template>
  <div class="ModelDescriptionCollapse">
    <div
      class="description"
      :class="{ collapsed: collapsed }"
      v-html="description"
    >
    </div>
    <div
      v-if="!collapsed"
      class="handler"
    >
      <a @click="toggle()">{{ $t('readMore') }}</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModelDescriptionCollapse',
  props: {
    description: String,
  },
  data () {
    return {
      collapsed: false,
    }
  },
  methods: {
    toggle () {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="scss" scoped>
  .ModelDescriptionCollapse {
    font-size: 14px;
    color: $text-gray;

    .description {
      display: block;
      height: 48px;
      overflow-y: hidden;
    }
    .description.collapsed {
      height: auto;
      overflow-y: visible;
    }

    .handler {
      margin: 6px 0 0 0;

      a {
        text-decoration: none;
        border-bottom: dashed 1px $text-gray;
        cursor: pointer;
      }
    }
  }
</style>
