<template>
  <div class="attribute" :class="{ '__pointer': isLongValue}">
    <div class="attribute-content">
      <span class="attribute--key">{{attrKey}}:</span>
      <span class="attribute--value">{{value}}</span>
    </div>
    <div class="attribute-tip" v-if="isLongValue">{{value}}</div>
    <div class="attribute-dots" v-if="isLongValue">...</div>
  </div>
</template>

<script>
export default {
  name: 'Attribute',
  props: {
    attrKey: {
      default: '',
    },
    value: {
      default: '',
    },
  },

  data () {
    return {
      isLongValue: false,
    }
  },

  mounted () {
    const contentEl = this.$el.querySelector('.attribute-content')
    const keyEl = this.$el.querySelector('.attribute--key')
    const valueEl = this.$el.querySelector('.attribute--value')
    const contentWidth = contentEl.clientWidth
    const keyWidth = keyEl.clientWidth
    const valueWidth = valueEl.clientWidth

    this.isLongValue = contentWidth - keyWidth - valueWidth < 0
  },
}
</script>

<style lang="scss" scoped>

.attribute {
  line-height: 16px;
  font-size: 14px;
  position: relative;
  padding-right: 12px;
  box-sizing: border-box;

  &-content {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  &--key {
    white-space: nowrap;
    color: $text-gray;
    padding-right: 3px;
  }

  &--value {
    color: $text-black;
    position: relative;
    white-space: nowrap;
  }

  &-tip {
    position: absolute;
    top: 20px;
    left: 0;
    opacity: 0.01;
    transition: opacity 0.2s ease;
    pointer-events: none;
    background-color: $background-dark;
    color: #fff;
    padding: 2px 4px;
    font-size: 12px;
    z-index: 1;
  }

  &-dots {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.__pointer {
    cursor: pointer;
  }

  &:hover &-tip {
    opacity: 1;
  }
}

</style>
