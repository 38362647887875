<template>
  <div class="model-info">
    <div class="model-info__name">
      <p>{{ model.name }}</p>
      <p v-if="model.nameCn">{{ model.nameCn }}</p>
    </div>
    <div class="model-info__parameters">
      <Attribute
        v-for="attribute in modelParams"
        :key="attribute.key"
        :attrKey="attribute.key"
        :value="attribute.value"
      />
    </div>
    <div class="model-info__description">
      <ModelDescriptionCollapse
        :description="model.description"/>
    </div>
    <template v-if="generalAttributes.length > 0">
      <div class="model-info__separator">
      </div>
      <div class="model-info__attributes">
        <div class="model-info__attributes-title">
          {{ $t('models.generalAttributes') }}
        </div>
        <Attribute
          v-for="attribute in generalAttributes"
          :key="attribute.key"
          :attrKey="attribute.key"
          :value="attribute.value"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Attribute from '@/components/ui/layout/models/Attribute'
import ModelDescriptionCollapse from '@/components/ui/collapsed/ModelDescriptionCollapse.vue'
import helpers from '@/helpers/helpers'

export default {
  name: 'ModelPage',
  components: {
    Attribute,
    ModelDescriptionCollapse,
  },
  computed: {
    ...mapState({
      model: state => state.products.myModel,
      productsAvailable: state => state.products.productsAvailable,
    }),
    modelParams () {
      const brand = {
        key: this.$t('listBrand'),
        value: this.model.brand ? this.model.brand.name : '',
      }
      const category = {
        key: this.$t('models.category'),
        value: this.model.category ? this.model.category.name : '',
      }
      const status = {
        key: this.$t('orderStatus'),
        value: this.model.moderation,
      }

      const result = [
        brand,
        category,
        status,
      ]

      if (this.model.type) {
        const type = {
          key: this.$t('models.type'),
          value: this.model.type.name,
        }

        result.unshift(type)
      }

      return result
    },
    generalAttributes () {
      return helpers.productAttributes(this.model.products, this.productsAvailable).general
    },
  },
}
</script>

<style lang="scss" scoped>
  .model-info {
    width: 387px;
    padding: 30px 20px;
    border-radius: 20px;
    background: $color-white;
    line-height: 1.2;
    border: 1px solid #D4DADF;
    box-shadow: 0 0 24px 0 #0000000F;

    &__name {
      margin: 0 0 16px 0;
      font: {
        size: 20px;
        weight: 800;
      }
    }

    &__parameters {
      margin: 0 0 16px 0;
      padding: 16px 16px 8px 16px;
      background: $color-background;
      font-size: 16px;

      .attribute {
        margin: 0 0 8px 0;
      }
    }

    &__description {
      margin: 0 0 20px 0;
    }

    &__separator {
      display: block;
      margin: 0 0 20px 0;
      border-bottom: solid 1px $color-line;
    }

    &__attributes {

      .attribute {
        margin: 0 0 4px 0;
        font-size: 14px;
      }
    }

    &__attributes-title {
      margin: 0 0 12px 0;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }
</style>
