<template>
  <ul class="breadcrumbs">
    <li class="breadcrumbs__item">
      <button
        class="back"
        @click="goBack"
      >
        <svg-icon
          icon-name="arrow"
          class-name="back-arrow"
        />

        {{ $t('back')}}
      </button>
    </li>
    <li class="breadcrumbs__item">{{ $t(currentRoute) || currentRoute}}</li>
  </ul>
</template>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 14px 0;
}

.breadcrumbs__item {
  position: relative;
  color: $text-gray;

  &:not(:last-child) {
    margin-right: 32px;
    cursor: pointer;
  }
}

.back {
  border-radius: 23px;
  border: 1px solid $color-primary;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 16px;
  color: $color-primary;
  cursor: pointer;
  background-color: transparent;

  &-arrow {
    width: 4px;
    height: 10px;
    fill: transparent;
    stroke: $color-primary;

  }
}
</style>

<script>
import SvgIcon from '@shared/components/SvgIcon'
import { useRouter } from 'vue-router'
import { computed } from '@vue/compat'

export default {
  setup () {
    const currentRoute = computed(() => {
      return useRouter().currentRoute.value?.name || ''
    })
    return { currentRoute }
  },
  components: { SvgIcon },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
