<template>
  <div class="main-content">
    <base-bread-crumbs />

    <div class="title text-20-800">New model creation</div>

    <div class="main-content__with-sidebar">
      <div class="sidebar">
        <StepsContainer
          v-if="isModelCreating"
          :steps="steps"
          :style="{height: '136px'}"
        />
        <ModelCard v-if="!isModelCreating"/>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs.vue'
import StepsContainer from '@/components/ui/layout/steps/StepsContainer'
import ModelCard from '@/components/ui/layout/ModelCard.vue'
import { mapState } from 'vuex'

export default {
  name: 'AddNewModelPage',
  components: {
    BaseBreadCrumbs,
    StepsContainer,
    ModelCard,
  },
  data () {
    return {
      steps: [
        {
          id: 1,
          name: this.$t('models.info'),
          isCurrent: true,
          isDone: false,
          route: 'models.creating',
          hasLine: true,
        },
        {
          id: 2,
          name: this.$t('models.variation'),
          isCurrent: false,
          isDone: false,
          route: 'models.creatingProduct',
          hasLine: false,
        },
      ],
      isModelCreating: false,
    }
  },
  computed: {
    ...mapState({
      model: state => state.products.myModel,
    }),
  },
  methods: {
    stepCompute (routeName) {
      this.steps.map(step => {
        step.isCurrent = step.route === routeName

        switch (routeName) {
          case this.$t('models.creatingProduct'):
            if (step.route === this.$t('models.creating')) {
              step.isDone = true
            }
        }
      })
    },
  },

  mounted () {
    this.isModelCreating = this.$route.path === '/create/model'
    this.stepCompute(this.$route.name)
  },

  beforeRouteUpdate (to, from, next) {
    this.stepCompute(to.name)
    next()
  },
}
</script>

<style lang="scss" scoped>
  .sidebar {
    background: transparent;
    box-sizing: border-box;
  }
  .title {
    margin: 15px 0;
  }
</style>
